import { format, isToday, isWeekend, startOfToday } from 'date-fns';
import { arrayOf, bool, func } from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getClassNames, isEmpty } from '@neslotech/utils';

import { LEAVE_ALLOCATION } from '../../../../../tool/constant';
import { USER_TYPE } from '../../../../../tool/prop-types';
import { calculateDaysOfWeek, filterAllocations } from '../../../../../tool/scheduling.util';

import { SchedulingAllocationContainer } from '../../../../../container/scheduling/allocation/SchedulingAllocation.container';

import './scheduling-calendar-view.scss';

export const SchedulingCalendarView = ({
  userHeights,
  scrollToToday,
  setScrollToToday,
  users = Array.from({ length: 6 }),
  personal = false
}) => {
  const calendarRef = useRef(null);
  const daysOfWeek = calculateDaysOfWeek();

  const allocations = useSelector(
    ({ scheduling_allocation_store }) => scheduling_allocation_store.allocations
  );

  useEffect(() => {
    setScrollToToday(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrollToToday) {
      return;
    }

    const today = startOfToday();
    const todayIndex = daysOfWeek.findIndex((day) => day.toDateString() === today.toDateString());
    const scrollElement = calendarRef.current;

    if (scrollElement) {
      const todayElement = scrollElement.children[todayIndex];
      if (todayElement) {
        //used + 12 on the offset because todayElement.offsetLeft to scroll to the start of today's element
        scrollElement.scrollLeft = todayElement.offsetLeft + 12;
      }
    }

    setScrollToToday(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToToday, daysOfWeek]);

  const userList = useMemo(() => (isEmpty(users) ? Array.from({ length: 6 }) : users), [users]);

  return (
    <section className="scheduling-calendar__view">
      <div className="scheduling-calendar__week" ref={calendarRef}>
        {daysOfWeek.map((day) => (
          <div key={day}>
            <div
              className={getClassNames('scheduling-calendar__day', {
                weekend: isWeekend(day),
                today: isToday(day)
              })}
            >
              <span>{format(day, 'd')}</span>
              <span>{format(day, 'cccc')}</span>
            </div>
            <div>
              {userList.map((user, index) => {
                let height = 216;
                if (userHeights && user) {
                  height = userHeights[user.id];
                }

                const allocationsForDay = filterAllocations(allocations, day, user?.id);
                const taskAllocationsForDay = allocationsForDay.filter(
                  (allocation) => allocation.allocationType !== LEAVE_ALLOCATION
                ).length;

                const hasOvertime =
                  allocationsForDay.reduce((accum, allocation) => accum + allocation.hours, 0) > 8;
                const overtimeHeight = height - 216;
                const overtimeTop = 216 + taskAllocationsForDay * 27 - taskAllocationsForDay * 10;

                return (
                  <div>
                    <div
                      key={user?.id ?? index}
                      style={{
                        height: `${height}px`
                      }}
                      className={getClassNames('scheduling-calendar__tasks', {
                        weekend: isWeekend(day),
                        personal
                      })}
                    >
                      {hasOvertime && (
                        <div
                          className="scheduling-calendar__overtime"
                          style={{ top: `${overtimeTop}px`, height: `${overtimeHeight}px` }}
                        />
                      )}
                      <SchedulingAllocationContainer day={day} userId={user?.id} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

SchedulingCalendarView.propTypes = {
  users: arrayOf(USER_TYPE),
  personal: bool,
  scrollToToday: bool.isRequired,
  setScrollToToday: func.isRequired
};
